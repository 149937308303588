/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountAddressForm {}

.Field_id_country_id {
    display: none;
}
.CheckoutShipping .CheckoutAddressForm{
    .FieldForm-Fields{
        @media (max-width:1279px){
            display: flex;
            flex-wrap: wrap;
        }
        .Field{
            &:nth-child(1){
                order: 1;
                @media (max-width:1279px){
                    width: 100%;
                }
            }
            &:nth-child(2){
                order: 2;
                @media (max-width:1279px){
                    width: 100%;
                }
            }
            &:nth-child(3){
                order: 3;
                @media (max-width:1279px){
                    width: 100%;
                }
            }
            &:nth-child(4){
                order: 8;
                @media (max-width:1279px){
                    width: 100%;
                }
            }
            &:nth-child(5){
                order: 6;
                @media (max-width:1279px){
                    width: 100%;
                }
            }
            &:nth-child(6){
                order: 5;
                @media (max-width:1279px){
                    width: 100%;
                }
            }
            &:nth-child(7){
                order: 4;
                @media (max-width:1279px){
                    width: 100%;
                }
            }
            &:nth-child(8){
                order: 7;
                @media (max-width:1279px){
                    width: 100%;
                }
            }
        }
    }
}