/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .MyAccountMyOrders {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;

    @include mobile {
        grid-gap: 28px;
    }

    &-Button {
        @include desktop {
            position: absolute;
            inset-block-start: -55px;
            inset-inline-end: 0;
        }
    }

    &-Wrapper {
        font-size: 14px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        &.MyDownloadable {
            width: auto;
            max-width: initial;
        }

        th{
            background: $white;
            text-transform: uppercase;
            font-size: 14px;
            line-height: inherit;
            padding: 0 10px 17px 10px;
            border-bottom: solid 1px $grey-4;
            &:first-child{
                width: 250px;
            }
        }
        > .orderlistheading{
            padding: 10px 0;
        }
    }
}
