/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountOrderTabs {
    display: flex;
    flex-direction: column;
    border-block-end: 1px solid var(--secondary-base-color);

    @include desktop {
        flex-direction: row;
        margin-block-end: 30px;
    }

    &-Wrapper {
        @include mobile {
            padding: 0;
        }

        .ExpandableContent-Heading {
            text-transform: uppercase;
        }

        .ExpandableContent {
            border-block-end: 0;
        }

        & > :first-child {
            border-block-start: 0;
        }
    }
}
