/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .MyAccountOverlay {
    &-Legend {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        legend {
            padding: 8px 0;
        }
        .Field{
            width: 49%;
            margin-block-start: 20px !important;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
    }
    

    &-SignUpButton {
        margin-block-start: 20px;

        @include mobile {
            margin-block-end: 18px;
        }
    }

    &-SignInButton {
        height: fit-content;
    }

    &-PasswordBlock {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 16px;
        }
    }

}

.MyAccountPopup-Action.MyAccountPopup-Action_state_createAccount{
    width: 800px;
    @include mobile {
        width: 100%;
    }
}

.MyAccountOverlay-Buttons .emailSendRegistermsq{
    font-weight: 800;
    color: #0fa30f;
}

.MyAccountOverlay-Legend{
    .Custom-true{
        pointer-events: none;
        opacity: 0.5;
    }
}
/* eslint-enable */
