/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
.MyAccountAddressTableWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: $default-primary-base-color;
    .MyAccountAddressTable,
    .MyAccountAddressTableDefault {
        font-size: 14px;
        line-height: 22px;
        width: 350px;
        margin-bottom: 35px;
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        span{
            display: block;
        }
        > strong{
            font-size: 14px;
            color: $default-primary-base-color;
        }
        button{
            padding: 0;
            margin: 0;
            font-size: 14px;
            font-weight: normal;
            text-transform: capitalize;
            height: auto;
            width: auto;
            border-radius: 0;
            border-bottom: solid 1px $default-primary-base-color;
            margin-top: 30px;
            &:not([disabled]):hover{
                height: auto;
                width: auto;
                padding: 0;
                border-radius: 0;
            }
            + button{
                margin-left: 30px !important;
            }
        }
    }
}