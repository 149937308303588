/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountSignIn {
    
}
.MyAccountOverlay-Buttons{
    text-align: center !important;
    margin-top: 25px !important;
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    align-items: center;
}

.MyAccountPopup-Action_state_signIn{
    .TimerButton{
        width: 100% !important;
        border: none !important;
        padding: 0 !important;
        height: auto !important;
    }
}

.SignInForm .emailSendRegistermsq{
    margin-top: 20px;
    text-align: center;
    font-weight: 800;
    color: #0fa30f;
}