/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .LoginAccount {
    .ContentWrapper {
        min-height: calc(100vh - var(--header-total-height));
        padding-block-start: 28px;
        padding-block-end: 50px;
        padding-inline: 20px;

        @include desktop() {
            padding-block-start: 25px;
        }
    }

    &-InnerWrapper {
        @include desktop() {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            padding-block-start: 0;
            grid-template-columns: 1fr 1fr;
            grid-gap: 100px;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            margin-block-end: 7px;
            border: 0;

            @include desktop {
                margin-block-start: 20px;
            }
        }

        input {
            @include mobile {
                width: 100%;
            }

            @include desktop {
                width: 100%;
            }
        }

        > div {
            flex: 1;

            @include desktop {
                margin: 0 10px;
            }
        }

        h4 {
            padding-block-end: 7px;
            text-align: center;
        }

        p {
            margin-block-end: 20px;
        }
    }

    .Loader {
        margin: 0;
    }

    &-CreateAccountWrapper {
        margin-block-start: 24px;
        display: flex;
        align-items: center;

        h4 {
            margin-block-end: 0;
        }

        button {
            width: auto;
            margin-inline-start: 5px;
        }
    }

    &-SignInWrapper {
        @include desktop {
            max-width: 400px;
        }
        > h3{
            font-size: 24px;
            line-height: 36px;
            margin: 0;
            padding: 0;
        }
    }

    &-CreateAccount-Wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 27px 0;
        button{
            width: auto;
            margin-left: 10px;
            font-weight: 700;
        }
    }
}

