/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountPersonalInfo {
    
}
.PersonalInfoEdit{
    width: 450px;
    margin-bottom: 30px;
    @include mobile {
        width: 100%;
    }
    .Field{
        margin-top: 14px;
    }
}
.Change-ButtonWrapper{
    button{
        font-size: 14px;
        line-height: 24px;
        display: block;
        cursor: pointer;
        margin: 10px 0;
        position: relative;
    }
}

.MyAccountCustomerPopup{
    input, textarea, select{
        width: 100%;
    }
}