/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountStoreCredit {
    // TODO style the element
    overflow-x: auto;
    > h3{
        span{
            color: $theme-red-color;
        }
    }
    table{
        font-size: 14px;
        width: auto;
        min-width: 100%;
        max-width: 100%;
        th{
            background: $white;
            border-bottom: solid 1px $grey-4;
            height: 40px;
            vertical-align: middle;
            font-size: 14px;
            padding: 0;
        }
        td{
            padding: 12px ​0;
            &.delta_value_isNegative{
                color: red;
            }
            &.delta_value_isPositive{
                color: green;
            }
        }
    }
}
