/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --my-account-content-background: #{$white};
    --my-account-wrapper-margin-mobile: 16px;
    --my-account-content-border: 1px solid rgba(10, 9, 3, 0.16);
}

.MyAccount {
    background: var(--my-account-background);

    &-Wrapper {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 0;
        padding-block-start: 12px;
        padding-block-end: 12px;

        @include mobile {
            grid-column-gap: 28px;
            padding: 0;
            grid-template-columns: 1fr;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    &-TabContent {
        background: var(--my-account-content-background);
        color: $default-primary-base-color;
        border: solid 1px $grey-4;
        @include mobile {
            margin-inline: var(--my-account-wrapper-margin-mobile);
            padding: 30px 20px;
            overflow-x: hidden;
        }

        @include desktop {
            grid-row: span 2;
            padding: 30px 20px;
        }
        &_isHidden{
            padding: 0;
            border: none;
        }
    }

    &-Heading {
        text-transform: uppercase;
        margin: 0;
        margin-block-end: 20px;
        font-size: 22px;
        color: $default-primary-base-color;
        padding-block-end: 22px;
        border-bottom: solid 1px $grey-4;
        display: flex;
        align-items: center;
        svg {
            top: 0px;
            margin-right: 8px;
        }

        @include mobile {
            margin-block-end: 28px;
            // display: block;
        }
        &.sub{
            margin-block-end: 0;
        }

        &_isHidden {
            display: none;
        }
    }

    &-SubHeading {
        color: var(--secondary-dark-color);
        font-size: 16px;
    }
}
