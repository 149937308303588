/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --checkbox-label-color: #0a0a0a;
    --my-account-input-label-color: rgba(10, 10, 10, .59);
    --my-account-overlay-width: 100%;
    --my-account-overlay-border-width: 1px;
    --popup-bottom-offset: 40px;
}

.MyAccountPopup {
    --overlay-background: var(--overlay-content-background);

    justify-self: flex-end;
    grid-column: 3;
    inset-inline-end: 0;
    padding-block-start: 12px;

    @include mobile {
        padding-block-start: 0;
    }

    @include desktop {
        position: fixed;
        inset-block-start: 0;
        inset-inline-end: 0 !important;
        padding: 0;
        width: 100%;
        left: 0 !important;
    }

    // @include mobile {
    //     height: auto;
    //     padding-block-end: 0;
    //     inset-block-start: 0;
    //     position: relative;
    // }

    .Popup-Content {
        .Popup-Header{
            .Popup-Heading{
                display: none;
            }
            .Popup-CloseBtn{
                text-align: right;
            }
        }
    }

    &::before {
        display: none;
    }

    button {
        width: 100%;

        &.Button_likeLink {
            width: auto;
        }
    }

    .MyAccountOverlay-SignUpButton,
    .TimerButton{
        width: 365px;
    }

    .TimerButton{
        border: none !important;
        padding: 0 !important;
        height: auto !important;
    }

    &-ForgotPassword {
        width: auto;
        margin-block-start: 8px;
    }

    input {
        width: 100%;
    }

    &-Heading {
        font-size: 26px;
        font-weight: 700;
        padding-block-start: 0;
        margin-block-end: 0;
        color: $default-primary-base-color;
        line-height: 31px;
        @include mobile {
            padding-block-end: 14px;
            padding-inline: 14px;
        }
    }

    &-Notification {
        background-color: var(--primary-dark-color);
        padding: 24px;

        @include mobile {
            padding: 28px;
        }
    }

    &-Navigation {
        li {
            margin: 0;
            padding: 0;

            &::before {
                content: none;
            }

            &:last-child {
                margin-block-end: 0;
            }

            a {
                display: block;
            }
        }

        a,
        button {
            width: 100%;
            text-align: start;
            padding: 12px 24px;
            font-size: 14px;

            @include mobile {
                padding: 14px 28px;
                font-size: 17px;
            }

            &:hover {
                background-color: var(--color-cart-light);
                text-decoration: none;
            }

            &:focus {
                text-decoration: none;
            }

            @include desktop {
                padding: 6px;
            }
        }

        @include desktop {
            padding: 6px;
        }
    }

    &-Action form,
    &-Additional_state_signIn {
        @include mobile {
            padding-block-start: 14px;
            padding-inline: 14px;
        }
    }

    &-SignInLink {
        width: auto;
        margin-block-start: 0;
    }

    &-ResetPassword {
        width: 100%;
        margin-block-start: 24px;
    }

    &-Action {
        display: grid;
        grid-template-rows: max-content 1fr;

        &_state {
            &_createAccount {
                h4 {
                    padding-inline-end: 12px;
                    display: inline;

                    @include mobile {
                        padding-inline-end: 14px;
                    }
                }

                h3 {
                    margin-block-end: 12px;

                    @include mobile {
                        margin-block-end: 14px;
                    }
                }

                .Field {
                    margin-block-start: 8px;

                    @include mobile {
                        margin-block-start: 10px;
                    }
                }

                @include mobile {
                    padding-block-start: 14px;
                }
            }

            &_forgotPassword {
                h4 {
                    width: initial;
                }

                button {
                    font-weight: bold;
                }
            }

            &_signIn {
                h4 {
                    text-align: center;
                }

                .Form .Button_likeLink {
                    margin-block-start: 8px;
                    margin-block-end: 30px;
                }
            }

            &_loggedIn {
                .MyAccountOverlay-Heading {
                    @include desktop {
                        display: none;
                    }
                }

                @include desktop {
                    width: 180px;
                    padding: 0;
                }
            }
        }

        h4 {
            font-size: 14px;
            font-weight: bold;
            margin-block-end: 6px;

            @include mobile {
                font-size: 16px;
                margin-block-end: 7px;
            }
        }

        h3 {
            margin-block-start: 0;
        }
    }

    &-Additional {
        padding-block-start: 24px;

        section {
            display: flex;

            padding-block-start: 20px;

            &:first-of-type {
                padding-block-start: 0;
            }
        }

        &_state {
            &_createAccount {
                padding-block-start: 24px;

                @include mobile {
                    padding-block-start: 28px;
                    display: none;
                }

                h4 {
                    margin: 0;
                    padding-inline-end: 0;
                }

                button {
                    width: initial;
                    margin-block: 0;
                    margin-inline-start: 5px;

                    &:hover,
                    &:focus {
                        margin-block: 0;
                    }
                }
            }

            &_signIn {
                section {
                    align-items: center;
                }

                button {
                    margin-inline-start: 5px;
                }
            }

            &_confirmEmail {
                padding-block-start: 0;
            }

            &_forgotPassword {
                section {
                    display: flex;

                    button {
                        width: initial;
                        margin-block-start: 0;
                        padding-inline-start: 5px;
                        font-weight: 600;

                        &:hover,
                        &:focus {
                            padding-inline-start: 5px;
                        }
                    }

                    h4 {
                        margin-block-end: 0;
                        align-self: center;
                    }

                    &:last-of-type {
                        align-items: center;
                        padding-block-start: 1px;
                    }
                }
            }

            &_forgotPasswordSuccess {
                padding-block-start: 0;

                h4 {
                    font-size: 12px;
                    line-height: 1.5;
                    padding-block-end: 17px;
                    font-weight: normal;
                    letter-spacing: normal;

                    @include mobile {
                        font-size: 14px;
                        padding-block-end: 20px;
                    }
                }
            }
        }
    }

    &-CloseButton {
        &::before,
        &::after {
            background-color: var(--header-color);
            content: '';
            height: 20px;
            position: absolute;
            inset-inline-end: 27px;
            inset-block-start: -13px;
            transform-origin: 50% 50%;
            width: 1px;
            z-index: 1;
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }

    &-ForgotPasswordButton {
        @include desktop {
            display: none;
        }
    }

    &-Buttons {
        margin-block-start: 6px;
        text-align: center;

        @include mobile {
            margin-block-start: 7px;
        }
    }

    &-Button {
        &_likeLink {
            font-size: 12px;
            margin-block-start: 14px;

            @include mobile {
                font-size: 13px;
                margin-block-start: 17px;
            }
        }
    }

    &-Checkbox {
        margin-block: 11px 24px;
    }

    &-ResetPassword {
        margin-block-start: 24px;
    }

    .Field{
        margin-block-start: 29px;
        .Field-Label{
            font-size: 16px;
            font-weight: 700;
            color: $default-primary-base-color;
            padding-bottom: 5px;
        }
        &_type_otp{
            width: 49%;
            margin-left: auto;
        }
    }

    .MyAccountOverlay-ForgotPassword{
        float: right;
    }
    .MyAccountOverlay-Additional{
        text-align: center;
        section{
            justify-content: center;
            font-size: 14px;
            h4{
                margin-bottom: 0;
                font-weight: 400;
                color: $default-primary-base-color;
            }
            button{
                font-weight: 700;
            }
        }
    }

}

.MyAccountSignIn{
    display: flex;
    flex-wrap: wrap;
    margin-block-start: 20px;
    margin-block-end: 20px;
    > div.MyAccountSignIn-Email, div.MyAccountSignIn-Otp{
        font-size: 16px;
        font-weight: 700;
        padding-left: 25px;
        margin-right: 30px;
        position: relative;
        @media screen and (max-width: 500px) {
            flex: 0 0 100%;
            margin-right: 0;
            margin-bottom: 15px;
        }
        cursor: pointer;
        &:before{
            width: 16px;
            height: 16px;
            border: solid 1px #D9D9D9;
            border-radius: 500%;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
        }
        &_isActive{
            &:after{
                width: 8px;
                height: 8px;
                background: #E31E3C;
                border-radius: 500%;
                content: "";
                position: absolute;
                left: 5px;
                top: 5px;
            }
        }
    }
}


.MyAccountOverlay-SignInWithOtpButton{
    margin-block-start: 20px;
}

.MyAccountPopup-Action_state_signIn{
    .Field_type_otp{
        width: 100%;
        margin-left: auto;
    }
}