/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .MyAccountDashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    &-CustomerName{
        font-size: 40px;
        line-height: 40px;
        color: $default-primary-base-color;
        margin-bottom: 40px;
        font-weight: 700;
        @include mobile {
            font-size: 25px;
            line-height: 25px;
            margin-bottom: 20px;
        }
    }

    @include mobile {
        grid-gap: 28px;
    }

    @include desktop {
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }

    &-DefaultAddress {
        width: calc(50% - 10px);
        flex-wrap: wrap;
        &:first-child{
            padding-right: 10px;
        }
        &:last-child{
            padding-left: 10px;
        }
        strong, span{
            display: block;
            font-size: 14px;
            line-height: 20px;
            flex: 0 0 100%;
        }
        strong{
            text-transform: capitalize;
            margin-bottom: 7px;
        }
        @media screen and (max-width: 650px) {
            width: 100%;
            &:first-child{
                padding-right: 0;
                padding-bottom: 10px;
            }
            &:last-child{
                padding-left: 0;
                padding-top: 10px;
            }
        }
    }

    &-AddressWrapper {
        border: solid 1px $grey-4;
        padding: 25px;
        
        @include desktop {
            grid-column: 2;
        }
        h4{
            margin-bottom: 20px;
            padding-bottom: 20px;
            font-size: 22px;
            line-height: 40px;
            text-transform: uppercase;
            font-weight: 700;
            color: $default-primary-base-color;
            display: flex;
            align-items: center;
            border-bottom: solid 1px $grey-4;
            svg{
                margin-right: 10px;
            }
            a{
                padding: 0;
                margin: 0;
                margin-left: auto;
                font-size: 14px;
                font-weight: normal;
                text-transform: capitalize;
                height: auto;
                border-radius: 0;
                border-bottom: solid 1px $default-primary-base-color;
                line-height: 19px;
                &:not([disabled]):hover{
                    padding: 0;
                    margin: 0;
                    margin-left: auto;
                    height: auto;
                    border-radius: 0;
                }
            }
            @include mobile {
                font-size: 17px;
                line-height: 27px;
            }
        }
    }

    &-OrderWrapper {
        border: solid 1px $grey-4;
        padding: 25px;
        @include desktop {
            grid-column: 1;
        }
        h4{
            margin-bottom: 20px;
            padding-bottom: 20px;
            font-size: 22px;
            line-height: 40px;
            text-transform: uppercase;
            font-weight: 700;
            color: $default-primary-base-color;
            display: flex;
            align-items: center;
            border-bottom: solid 1px $grey-4;
            svg{
                margin-right: 10px;
            }
            a{
                padding: 0;
                margin: 0;
                margin-left: auto;
                font-size: 14px;
                font-weight: normal;
                text-transform: capitalize;
                height: auto;
                border-radius: 0;
                border-bottom: solid 1px $default-primary-base-color;
                line-height: initial;
                &:not([disabled]):hover{
                    padding: 0;
                    margin: 0;
                    margin-left: auto;
                    height: auto;
                    border-radius: 0;
                }
            }
            @include mobile {
                font-size: 17px;
                line-height: 27px;
            }
        }
    }

    &-Table{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li{
            margin: 0;
            padding: 0;
            flex: 0 0 40%;
            @media screen and (max-width: 650px) {
                flex: 0 0 100%;
                &:first-child{
                    padding-bottom: 10px;
                }
                &:last-child{
                    padding-top: 10px;
                }
            }
            &:before{
                display: none;
            }
            strong, span{
                display: block;
                font-size: 14px;
                line-height: 21px;
                color: $default-primary-base-color;
            }
            div.Actions{
                display: flex;
                justify-content: space-between;
                margin-top: 15px;
                button{
                    padding: 0;
                    margin: 0;
                    font-size: 14px;
                    font-weight: normal;
                    text-transform: capitalize;
                    height: auto;
                    border-radius: 0;
                    border-bottom: solid 1px $default-primary-base-color;
                    width: auto;
                    &:not([disabled]):hover{
                        padding: 0;
                        margin: 0;
                        margin-left: 0;
                        height: auto;
                        border-radius: 0;
                    }
                }
            }
        }
    }

    &-AddressWrapperContent{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &-CustomerDetails{
        list-style: none;
        margin: 0;
        padding: 0;
        color: $default-primary-base-color;
        li{
            color: $default-primary-base-color;
            font-size: 14px;
            &.Heading{
                font-weight: 700;
            }
            &:before{
                display: none;
            }
            button{
                padding: 0;
                margin: 0;
                margin-left: auto;
                font-size: 14px;
                font-weight: normal;
                text-transform: capitalize;
                height: auto;
                border-radius: 0;
                border-bottom: solid 1px $default-primary-base-color;
                margin-top: 10px;
                width: auto;
                &:not([disabled]):hover{
                    padding: 0;
                    margin: 0;
                    margin-left: auto;
                    height: auto;
                    border-radius: 0;
                    margin-top: 10px;
                }
            }
        }
    }

    &-Info {
        margin-block-end: 5px;
    }

    button + button {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }

        @include desktop {
            margin-inline-start: 12px;
        }
    }
}
