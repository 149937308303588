/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --my-account-order-table-row-hover-background: var(--secondary-base-color);
}
.MyAccountOrderTableRow {
    height: auto;
    margin: 0 0 10px 0;
    padding: 10px;
    border: 1px solid $grey-5;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .image{
        width: 15%;
        margin-right: 20px;
        .OrderImage{
            margin: 0;
            display: block;
            img{
                width: 100%;
                margin-right: 0;
            }
        }
    }

    .order-details{
        width: calc(100% - 15% - 20px);
        font-size: 13px;
    }

    &-DownloadLink {
        display: block;
    }

    &:hover {
        background-color: $grey-1;
        cursor: default;
    }

    > span, div{
        margin: 7px 0;
        button{
            height: 35px;
            line-height: 35px;
            margin-right: 10px;
            padding: 10px;
            font-size: 13px;
            @include mobile {
                width: auto;
            }
            @media screen and (max-width: 400px) {
                font-size: 10px;
                padding: 8px;
                height: 30px;
                line-height: 30px;
                margin-right: 5px;
            }
            &:hover{
                padding: 10px !important;
                height: 35px !important;
                line-height: 35px;
            }
        }
    }

    .OrderImage{
        display: flex;
        img{
            width: 12%;
            margin-right: 15px;
        }
    }

    .viewButton{
        display: inline-block;
        font-size: 14px;
        margin-block-end: 10px;
        font-weight: 400;
        color: $default-primary-base-color;
        position: relative;
        // float: left;
        clear: both;
        cursor: pointer;
        &::after{
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0;
            background: $default-primary-base-color;
            width: 100%;
            height: 1px;
        }
    }
    

    .orderlistheading{
        font-size: 13px;
        color: $default-primary-base-color;
        vertical-align: top;
        width: 100%;
        padding: 0px 0;
       
        &:first-child{
            width: 250px;
        }
        
        > a{
            display: inline-block;
            font-size: 14px;
            margin-block-end: 10px;
            font-weight: 400;
            color: $default-primary-base-color;
            position: relative;
            float: left;
            clear: both;
            &:before{
                content: "";
                position: absolute;
                bottom: 0px;
                left: 0;
                background: $default-primary-base-color;
                width: 100%;
                height: 1px;
            }
        }
    }
}


@media screen and (max-width: 700px) {
    table.MyAccountMyOrders-Table {
      border: 0;
    }
    
    table.MyAccountMyOrders-Table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table.MyAccountMyOrders-Table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    table.MyAccountMyOrders-Table td {
        padding: 5px 0;
      display: block;
      text-align: right !important;
      width: 100% !important;
      position: relative;
      overflow: hidden;
      > button, a{
          float: right;
          clear: both;
      }
    }

    table.MyAccountMyOrders-Table tr{
        margin-bottom: 15px;
        padding-bottom: 30px;
        height: auto;
    }

    table.MyAccountMyOrders-Table td div.OrderImage{
        margin-left: auto;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        img{
            margin-right: 0;
            margin-block-end: 10px;
        }
        > span{
            flex: 0 0 100%;
        }
    }
    
    table.MyAccountMyOrders-Table td::before {
      content: attr(data-label);
      float: left;
      font-weight: 700;
      top: 50%;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
    }
    
    table.MyAccountMyOrders-Table td:last-child {
      border-bottom: 0;
    }
  }  
