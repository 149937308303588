/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --my-account-tab-list-item-color: #404040;
}

.MyAccountTabListItem {
    --border-width: 1px;
    --text-shadow: none;

    padding-inline-start: 0;
    margin-block-end: 0;

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;

        button {
            &::after {
                content: none;
            }
        }
    }

    &_isActive,
    &:hover {
        .MyAccountTabListItem-Button {
            font-weight: 700;
        }
    }

    &_isActive {
        .MyAccountTabListItem-Button {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &_isActive {
        @include mobile {
            display: none;
        }
    }

    &-Button {
        padding: 0;
        min-width: 100%;
        min-height: auto;
        text-align: start;
        text-shadow: var(--text-shadow);
        letter-spacing: .03ex;
        font-size: 16px;
        line-height: 40px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $default-primary-base-color;

        @include desktop {
            padding: 0;
            min-width: 200px;
        }

        .ChevronIcon {
            width: 14px;
            height: 14px;
            display: inline-block;
        }
    }
}
