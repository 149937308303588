/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .MyAccountViewOrder {
    font-size: 14px;

    &-Address,
    &-Products {
        margin-block-end: 20px;

        @include desktop {
            margin-block-start: 24px;
        }

        @include mobile {
            margin-block-end: 28px;
            overflow-x: unset!important;
        }
        .Font {
            &-Regular {
                font-weight: normal;
                display: block;
                text-decoration: underline;
                @include mobile{
                    min-width: 65px;
                }
            }
        }
    }

    &-Address{
        margin-block-end: 0;
    }

    &-heading{
        h2{
            margin: 0;
            padding: 0;
            font-size: 22px;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }

    &-ShippingWrapper{
        margin-top: 20px;
        h3{
            font-size: 22px !important;
            line-height: 40px;
            margin-bottom: 10px;
            text-transform: uppercase;
            border-bottom: solid 1px $grey-4;
        }
    }

    &-OrderWrapper{
        border-bottom: none !important;
        margin-bottom: 0 !important;
        width: 400px;
        justify-self: end;
        @media screen and (max-width: 500px) {
            width: 100%;
        }
        dl{
            grid-template-columns: 1fr 1fr !important;
            margin-top: 0 !important;
            grid-gap: 15px !important;
            dt.bold, dd.bold{
                font-weight: 700;
            }
            dt.discount, dd.discount{
                color: $theme-red-color;
            }

        }
    }

    &-ProductsWrapper{
        margin-bottom: 0 !important;
    }

    &-BackOrderHistory{
        border-top: solid 1px $grey-4;
        padding-top: 20px;
        margin-top: 28px;
        margin-bottom: 0 !important;
        text-align: right;
        display: grid;
        a{
            text-transform: uppercase;
            padding: 13px;
            width: 280px;
            font-size: 14px;
            line-height: inherit;
            border: solid 1px $default-primary-base-color;
            text-align: center;
            justify-self: end;
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
    }

    &-Products {
        width: 100%;
        margin-block-start: 0;
        table-layout: initial;

        @include mobile {
            margin-block-start: 14px;
        }

        tbody{
            border-bottom: var(--table-body-border);
        }

        td,
        th {
            vertical-align: top;
            font-size: 14px;
            line-height: 24px;
            min-width: 0;
            padding: 10px;
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
            }
            @include mobile{
                vertical-align: top;
            }
            @media(max-width:370px){
                // font-size: 12px;
                // padding: 5px;
            }
        }

        th{
            background: $white;
            text-transform: uppercase;
            font-size: 14px;
            line-height: inherit;
            padding: 17px 10px;
            border-top: solid 1px $grey-4;
            border-bottom: solid 1px $grey-4;
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
            }
        }

    }
    

    &-Thumbnail {
        width: 83px !important;
        height: 125px !important;
        padding: 0;
    }

    &-Row {
        height: 80px;
        padding: 10px 0;
        font-size: 14px;

        &:nth-child(even) {
            img {
                mix-blend-mode: multiply;
            }
        }
    }

    &-ProductsWrapper{
        > h4{
            font-size: 22px !important;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }

    &-Content {
        min-height: 200px;

        @include desktop {
            display: grid;
            grid-gap: 0;
        }
        > div{
            margin-bottom: 22px;
            text-transform: capitalize;
        }
        h2{
            &.Border{
                &-bottom{
                    border-bottom: solid 1px $grey-4;
                    padding-bottom: 15px;
                    line-height: 46px;
                    margin-top: 0;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 10px;
                    }
                }
            }
        }
        h4 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 10px;
    
            @include mobile {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 10px;
            }
        }
        dd{
            text-align: right;
        }
    
        dl {
            display: grid;
            margin-block-start: 10px;
            grid-template-columns: 2fr;
            grid-gap: 7px;
            margin-block-end: 10px;
    
            @include mobile {
                margin-block-start: 14px;
                grid-gap: 10px;
                margin-block-end: 14px;
            }
        }
    }

    

    

    td,
    th {
        min-width: 0 !important;
    }

    &-Address {
        .KeyValueTable-Wrapper {
            overflow-x: unset;
        }
        span{
            display: block;
            margin: 5px 0;
        }
    }
}
