/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .MyAccountOrderPopup {
    font-size: 14px;

    &-Address,
    &-Products {
        margin-block-end: 14px;

        @include desktop {
            margin-block-start: 24px;
        }

        @include mobile {
            margin-block-end: 28px;
            overflow-x: unset!important;
        }
    }

    &-OrderWrapper{
        border-bottom: none !important;
        margin-bottom: 0 !important;
    }

    &-Products {
        width: 100%;
        margin-block-start: 12px;
        table-layout: initial;

        @include mobile {
            margin-block-start: 14px;
        }

        td,
        th {
            vertical-align: middle;
        }
    }

    &-Thumbnail {
        width: 60px;
        height: 60px;
        padding: 0;
    }

    &-Row {
        height: 80px;
        padding: 10px 0;

        &:nth-child(even) {
            img {
                mix-blend-mode: multiply;
            }
        }
    }

    &-Content {
        min-height: 200px;

        @include desktop {
            display: grid;
            grid-gap: 0;
        }
        > div{
            border-bottom: solid 1px $grey-4;
            margin-bottom: 20px;
        }
    }

    h4 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;

        @include mobile {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }

    dt {
        font-weight: 700;
    }
    dd{
        text-align: right;
    }

    dl {
        display: grid;
        margin-block-start: 12px;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        margin-block-end: 12px;

        @include mobile {
            margin-block-start: 14px;
            grid-gap: 10px;
            margin-block-end: 14px;
        }
    }

    td,
    th {
        min-width: 0;
    }

    &-Address {
        .KeyValueTable-Wrapper {
            overflow-x: unset;
        }
        span{
            display: block;
            margin: 5px 0;
        }
    }
}
