/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CreateAccount {
    .ContentWrapper {
        padding-block-start: 28px;
        padding-block-end: 50px;
        padding-inline: 20px;

        @include desktop() {
            padding-block-start: 25px;
        }
    }

    &-InnerWrapper {
        @include desktop() {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            grid-template-columns: 1fr 1fr;
            grid-gap: 100px;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            margin-block-start: 20px;
            border: 0;
        }

        > div {
            flex: 1;
            margin: 0 10px;
        }

        button,
        input {
            min-width: 250px;

            @include mobile {
                width: 100%;
            }

            @include desktop {
                width: 100%;
            }
        }

        p {
            margin-block-end: 20px;
        }
    }

    .Loader {
        margin: 0;
    }

    &-CreateAccountWrapper{
        > h3{
            font-size: 24px;
            line-height: 36px;
            margin: 0;
            padding: 0;
        }
    }

    &-SignInWrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        > button{
            width: auto;
            min-width: auto;
            margin: 0;
            padding: 0;
            margin-left: 10px;
            font-weight: 700;
        }
    }
}
