/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountCustomerTable {
    &-CustomerData {
        border: solid 1px $grey-4;
        padding: 25px;

        @include desktop {
            grid-column: 1;
        }

        h4 {
            margin-bottom: 20px;
            padding-bottom: 20px;
            font-size: 22px;
            line-height: 40px;
            text-transform: uppercase;
            font-weight: 700;
            color: $default-primary-base-color;
            display: flex;
            align-items: center;
            border-bottom: solid 1px $grey-4;

            svg {
                margin-right: 10px;
            }

            a {
                padding: 0;
                margin: 0;
                margin-left: auto;
                font-size: 14px;
                font-weight: normal;
                text-transform: capitalize;
                height: auto;
                border-radius: 0;
                border-bottom: solid 1px $default-primary-base-color;
                line-height: 19px;

                &:not([disabled]):hover {
                    padding: 0;
                    margin: 0;
                    margin-left: auto;
                    height: auto;
                    border-radius: 0;
                }
            }

            @include mobile {
                font-size: 17px;
                line-height: 27px;
            }
        }
    }

}

.ForgotPassword {
    button {
        cursor: pointer;
        color: $default-primary-base-color;
    }

    button:hover {
        color: $default-primary-dark-color;
    }
}